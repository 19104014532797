import Routing from "./Routing";
import { QueryClient, QueryClientProvider } from "react-query";
import toast, { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import ChatwootWidget from "./components/ChatwootWidget/ChatwootWidget";
import { CustomToastProvider } from "./components/customtoast/CustomToastProvider";
import { ReactQueryDevtools } from "react-query/devtools";
import ReactGA from "react-ga4";
import { ToastContainer } from "react-toastify";
import "./App.css"
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

const queryClient = new QueryClient();




const App = () => {
  ReactGA.initialize("G-E1Q00ZZMG1");
  return (
    <>
      <CustomToastProvider>
        <QueryClientProvider client={queryClient}>
          <AnimatePresence>
            <Router>
              <Routing />
            </Router>
          </AnimatePresence>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
        </QueryClientProvider>
      </CustomToastProvider>

      <div>
        <Toaster
          toastOptions={{
            style: {
              color: "#023047",
              fontFamily: "Rubik,sans-serif",
              fontSize: "12px",
            },
            success: {
              iconTheme: {
                primary: "#8EAF55",
              },
              style: {
                color: "#023047",
              },
            },
          }}
        />
      </div>
      <ChatwootWidget />
      <ToastContainer/>
    </>
  );
};

export default App;
