import { useMutation } from "react-query"
import { instance2 as instance  } from "../instance"


export interface IRegisterPayload{
    email:string,
first_name:string,
last_name:string,
phone_number:string,
registration: string,
email_verified_at?:string
}
export const useRegistration=()=>{
    const register=async(payload:IRegisterPayload)=>{
        // const {data}= await instance.post(`/v2/tap-signup`, payload)
        const {data}= await instance.post(`/wishlists/tap/signup`, payload)
        return data
    }
    const mutate= useMutation(register)
    return mutate
}


interface IValidateEmailPayload{
email:string
}
export const useValidateEmail=()=>{
    const validate= async(payload:IValidateEmailPayload)=>{
        // const {data}= await instance.post(`/v2/validate-email`, {...payload})
        const {data}= await instance.post(`/wishlists/tap/validate-email`, {...payload})
        return data
    }
const mutate = useMutation(validate)
return mutate
}


interface IValidateOTPPayload{
    email:string,
    otp:string|number,
}
export const useValidateOTP=()=>{
    const validate= async(payload:IValidateOTPPayload)=>{
        const {data}= await instance.post(`/wishlists/tap/verify-email`, payload)
        return data
    }
const mutate = useMutation(validate)
return mutate
}


