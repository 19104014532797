import axios from "axios";

// const baseURL=`https://backend.steamfunfest.qwizfun.com`
const baseURL=`https://apifybkup.testassessify.com/api`
const baseURL2=`https://cocreate.9ijakids.com/api`
// const baseURL=process.env.REACT_APP_BASE_URL||`https://cors-anywhere.herokuapp.com/https://apifybkup.testassessify.com/api`

export const instance = axios.create({
    baseURL: baseURL,
  });
export const instance2 = axios.create({
    baseURL: baseURL2,
  });
  
  